import "./ContactPage.css"

function ContactPage() {
  return (
    <div id="contactpage-text">
      <div id="contactpage-experience">C0N7ACT</div>
    </div>
  );
}

export default ContactPage;
