import "./SkillsPage.css"

function SkillsPage() {
  return (
    <div id="skillspage-text">
      <div id="skillspage-experience">SKiLLS</div>
    </div>
  );
}

export default SkillsPage;
